import { hasRichTextContent } from '@lib/slice-machine/richText';
import { CustomRichText } from '@ui/slice-machine/CustomRichText';
import { SliceLayout } from '@ui/slice-machine/SliceLayout';
import { FC } from 'react';
import { DefaultRichTextVariation } from './types';
import { SxStyles } from '@lib/theme/sxTheme';
import { TextAlignment } from '@api/prismic/dynamic-pages/types';

export interface RichTextSliceProps {
  slice: DefaultRichTextVariation;
}

export const RichText: FC<RichTextSliceProps> = (props) => {
  const { slice } = props;
  const { backgroundColor, paddingTop, paddingBottom, width, text } =
    slice.primary;

  const styles = getStyles({
    alignment: slice.primary?.alignment,
  });

  return (
    <SliceLayout
      backgroundColor={backgroundColor}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      width={width}
    >
      {hasRichTextContent(text) && (
        <CustomRichText
          render={text}
          containerSx={styles.getValue('richText')}
        />
      )}
    </SliceLayout>
  );
};

interface StyleProps {
  alignment: TextAlignment | null;
}

const getStyles = ({ alignment = 'left' }: StyleProps) => {
  return new SxStyles({
    richText: {
      textAlign: alignment,
    },
  });
};
